<template>
  <div>
    <b-container fluid class="bg-login full-height">
      <b-row class="full-height">
        <b-col cols="12" md="6" class="right-side" style="overflow-y: auto;">
          <div class="inner-wrapper" data-anima="right" v-if="!loading">
            <span class="logo mb-5">
              <img v-if="!isGreenn" src="@/assets/img/G-digital-text.png" />
              <img
                v-else
                src="@/assets/greenn_sales/GREENN_SALES_.png"
                style="width: 198px; position: relative; left: -14px"
              />        
            </span>
            <router-link to="/dynamicRoute/login">
              <div class="voltar-login">
                <img src="@/assets/icons/arrow-page-rec.svg" />
                <p>Voltar</p>
              </div>
            </router-link>
            <div class="info-recuperacao">
              <h5>
                {{ $route.params.tk ? "Nova senha" : "Esqueceu sua senha?" }}
              </h5>
              <p v-if="!$route.params.tk">
                Preecha abaixo seu endereço de e-mail e enviaremos as instruções
                necessárias para redefinir sua senha na {{ nameSystem }}.
              </p>
              <p v-else>
                Preencha os campos abaixo para definir uma nova senha da sua
                conta {{ nameSystem }}
              </p>
            </div>

            <b-form
              v-if="!$route.params.tk"
              @submit.prevent="onSubmit"
              novalidate
            >
              <b-form-group label="Seu e-mail" label-for="email">
                <b-form-input
                  id="email"
                  placeholder="exemplo@exemplo.com"
                  v-model="email"
                  type="email"
                  name="email"
                  v-validate="'required|email'"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('email')">
                  Por favor, insira seu e-mail
                </b-form-invalid-feedback>
              </b-form-group>

              <b-row class="mt-4">
                <b-overlay
                  :show="loading"
                  rounded
                  opacity="0.6"
                  spinner-small
                  spinner-variant="primary"
                  class="btn-acoes"
                  :class="{ end: isMobile }"
                >
                  <BaseButton
                    variant="primary"
                    ref="button"
                    :disabled="loading"
                    type="submit"
                    style="margin-left: 15px"
                    class="btn-login"
                    :class="{ mg: isMobile }"
                    @click="onSubmit"
                  >
                    Recuperar minha senha
                  </BaseButton>
                </b-overlay>
              </b-row>
            </b-form>
            <b-form v-else>
              <div style="position: relative; margin-top: 10px">
                <b-form-group label="Sua senha" label-for="password">
                  <b-form-input
                    id="password"
                    placeholder="••••••••"
                    v-model="password"
                    type="password"
                    name="password"
                    v-validate="'required'"
                    @focus="isPasswordFocused = true"
                    @blur="isPasswordFocused = false"
                    
                  >
                </b-form-input>
                <span
                    class="mostrar-senha"
                    ref="mostrar_senha"
                    @click="mostrarSenha"
                    style="top: 50%; transform: translateY(-50%);"
                    >Mostrar</span
                  >
                  
                  
                  <b-form-invalid-feedback :state="!errors.has('password')">
                    Por favor, insira sua senha
                  </b-form-invalid-feedback>
                  <span v-if="password">
                    <div name="slide-fade" tag="div" class="password-steps">
                      <div
                        v-for="(validation, index) in passwordValidationSteps(
                          password
                        )"
                        :key="index"
                        class="container-valid mt-2"
                      >
                        <transition name="fade">
                          <img
                            src="@/assets/icons/register-check-mark-svgrepo-com.svg"
                            v-if="validation.validated"
                          />
                          <img
                            src="@/assets/icons/register-check-not.svg"
                            v-if="!validation.validated"
                          />
                        </transition>
                        <transition name="fade">
                          <span
                            style="font-size: 12px"
                            :class="{
                              'text-success': validation.validated,
                              'text-danger': !validation.validated,
                            }"
                          >
                            {{ validation.step }}
                          </span>
                        </transition>
                        <br />
                      </div>
                    </div>

                    
                  </span>
                </b-form-group>
              </div>

              <div style="position: relative; margin-top: 10px">
                <b-form-group
                  label="Confirme sua senha"
                  label-for="confirm_password"
                >
                  <b-form-input
                    id="confirm_password"
                    placeholder="••••••••"
                    v-model="confirm_password"
                    type="password"
                    name="confirm_password"
                    v-validate="'required'"
                  ></b-form-input>
                  <span
                    class="mostrar-senha"
                    ref="mostrar_confirm_senha"
                    @click="mostrarConfirmSenha"
                    >Mostrar</span
                  >
                  <b-form-invalid-feedback
                    :state="!errors.has('confirm_password')"
                  >
                    Por favor, insira sua senha
                  </b-form-invalid-feedback>
                </b-form-group>
                <div
                  v-if="
                    password && confirm_password && password != confirm_password
                  "
                  class="container-valid"
                >
                  <span class="text-danger mt-2"
                    >Senhas digitadas não coincidem</span
                  >
                </div>
              </div>

              <div class="mt-3"></div>

              <BaseButton
                variant="primary"
                ref="button"
                :disabled="loading"
                type="submit"
                style="margin-left: 15px"
                class="btn-salvar mt-3"
                :class="{ mg: isMobile }"
                @click="savePassword"
              >
                Salvar nova senha
              </BaseButton>
            </b-form>
          </div>

          <div
            v-if="loading"
            class="py-4 d-flex justify-content-center align-items-center"
          >
            <b-spinner variant="dark" label="Spinning"></b-spinner>
          </div>
        </b-col>
        <b-col cols="12" md="6" class="left-side" :style="'background-image: url('+backgroundImage+')'"> </b-col>
      </b-row>
      <!-- modals -->
    </b-container>
  </div>

</template>

<script>
import BaseView from "@/template/BaseView.vue";

import SenhaService from "@/services/resources/SenhaService";
const service = SenhaService.build();

export default {
  components: {
    BaseView,
  },
  data() {
    return {
      backgroundImage: "",
      email: "",
      password: "",
      confirm_password: "",
      isPasswordFocused: false,
      loading: false,
      client: {
        width: 0,
      },
    };
  },
  mounted(){
    if(this.isGreenn){
      this.backgroundImage = window.location.origin + '/banner2.webp'
    }else{
      this.backgroundImage =  window.location.origin + '/banner1.png'
    }
  },
  methods: {
    handleResize() {
      // this.client.width = window.innerWidth;
      this.client.width = window.innerWidth;
    },
    onSubmit() {
      if (this.email === "") {
        this.$grToast.toast("Insira seu e-mail de acesso", {
          title: "Recuperação de senha",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      var data = {
        id: "",
        email: this.email,
        type: "email verification",
      };
      service
        .create(data)
        .then((resp) => {
          this.$grToast.toast(
            "Um link de recuperação foi enviado para seu e-mail",
            {
              title: "Recuperação de senha",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          setTimeout(() => {
            this.$router.push("/dynamicRoute/login");
          }, 3000);
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            JSON.parse(err.response.data).user &&
            JSON.parse(err.response.data).user === "user not found."
          ) {
            this.$grToast.toast("E-mail inválido", {
              title: "Recuperação de senha",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }

          this.$grToast.toast("Erro ao enviar e-mail de recuperação", {
            title: "Recuperação de senha",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
    },
    savePassword() {
      var veriefed = 1;
      this.passwordValidationSteps(this.password).forEach((validation) => {
        if (!validation.validated && veriefed) {
          this.$grToast.toast("Siga as instruções para salvar sua nova senha", {
            title: "Recuperacação de senha",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          veriefed = 0;
          return;
        }
      });

      if (!veriefed) {
        return;
      }

      if (this.password === "") {
        this.$grToast.toast("Insira sua senha de acesso", {
          title: "Recuperacação de senha",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.confirm_password === "") {
        this.$grToast.toast("Confirme sua senha de acesso", {
          title: "Recuperacação de senha",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      if (this.password != this.confirm_password) {
        this.$grToast.toast("As senhas digitadas não coincidem", {
          title: "Recuperacação de senha",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }

      var data = {
        token: this.$route.params.tk,
        password: this.password,
      };

      service
        .create(data)
        .then((resp) => {
          this.$grToast.toast("Senha salva com sucesso", {
            title: "Recuperação de senha",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          setTimeout(() => {
            this.$router.push("/dynamicRoute/login");
          }, 3000);
        })
        .catch((err) => {
          if (
            err.response &&
            err.response.data &&
            JSON.parse(err.response.data)
          ) {
            this.$grToast.toast(JSON.parse(err.response.data).token[0], {
              title: "Recuperação de senha",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }
          this.$grToast.toast(
            "O token de recuperação não foi enviado, tente novamente",
            {
              title: "Recuperacação de senha",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        });
    },
    passwordValidationSteps(value) {
      const validations = [
        { step: "Pelo menos 8 caracteres", validated: value.length >= 8 },
        { step: "Letra maiúscula", validated: /[A-Z]/.test(value) },
        { step: "Letra minúscula", validated: /[a-z]/.test(value) },
        { step: "Número", validated: /\d/.test(value) },
        { step: "Símbolo", validated: /[@$!%*?&]/.test(value) },
      ];

      return validations;
    },
    mostrarConfirmSenha() {
      const input = document.querySelector("#confirm_password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_confirm_senha.innerText = "Ocultar";
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_confirm_senha.innerText = "Mostrar";
      }
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = "Ocultar";
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = "Mostrar";
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    isGreenn() {
      return this.$store.getters["getIsGreenn"];
    },
    isMobile() {
      return this.client.width <= 768;
    },
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-acoes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mg {
  margin-right: 15px !important;
  margin-left: 0px !important;
}
.end {
  justify-content: flex-end !important;
}
.bg-login {
  background: var(--greenn);
  overflow: hidden;
}
.btn-salvar {
  margin-left: 0px !important;
}
.voltar-login {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ededf0;
}
.voltar-login p {
  color: var(--gray01);
  font-size: 15px;
}

.voltar-login img {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-right: 20px;
  margin-bottom: 12px;
}
.info-recuperacao h5 {
  font-size: 16px;
  font-weight: 600;
}

.info-recuperacao p {
  color: #81858e;
  font-size: 14px;
  line-height: 1.5;
  margin: 20px 0 30px 0;
}

.left-side {
  background-image: url("~@/assets/banners/login.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right-side {
  background: #fff;
  padding: 20px 0px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;

  .inner-wrapper {
    margin-top: 90px;
    padding: 6% 18%;
    // width: 60%;

    .logo {
      img {
        width: 120px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 42px;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}
.container-valid {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 5px;
}
</style>